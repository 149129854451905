// Here you can add other styles
@import '_variables';
/* VARIABLES */
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
:root {
  /* CKEDITOR */
  --ck-highlight-marker-yellow:#fdfd77;
  --ck-highlight-marker-green:#62f962;
  --ck-highlight-marker-pink:#fc7899;
  --ck-highlight-marker-blue:#72ccfd;
  --ck-highlight-pen-red:#e71313;
  --ck-highlight-pen-green:#128a00;
}

/* STRUCTURE */
#root {
  max-width: 100%;
  overflow-x: hidden;
}

/* TIPOGRAPHY */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-italic {
  font-style: italic;
}
.text-underlined {
  text-decoration: underline;
}
.text-vertical-middle {
  vertical-align: middle;
}
.vertical-text-top {
  vertical-align: text-top;
}
.bold {
  font-weight: 700;
}
.normal {
  font-weight: normal;
}
.text-1rem {
  font-size: 1rem;
}
.danger {
  color:#{$holcim-bright-red};
}
.info {
  color: #{$holcim-dark-blue}
}
.uppercase {
  text-transform: uppercase;
}
.line-heigth-1 {
  line-height: 1;
}
.word-break {
  word-break: break-all;
}
.text-information {
  font-size: 14px;
  font-weight: 500;
  color: #{$holcim-dark-blue};
}

/* ALIGNMENTS AND SPACES */
.vertical-text-bottom {
  vertical-align: text-bottom;
}
.text-right-on-xs {
  text-align: right;
}
.pt-2-on-xs,
.pt-2-on-md {
  padding-top: 0.5rem !important;
}

/* BORDER */
.no-border {
  border: none;
}

/* COLORS */
.white {
  color: #ffffff;
}
.black {
  color: #000000;
}
.holcim-dark-blue {
  color: #{$holcim-dark-blue};
}
.holcim-bright-green {
  color: #{$holcim-bright-green};
}
.holcim-bright-red {
  color: #{$holcim-bright-red};
}
.holcim-dark-red {
  color: #{$holcim-dark-red};
}
.holcim-dark-turquoise {
  color: #{$holcim-dark-turquoise};
}
.holcim-yellow {
  color: #{$holcim-yellow};
}

/* BUTTONS */
.holcim-light-btn {
  --cui-btn-color: white;
}
.holcim-dark-btn {
  --cui-btn-color: black;
}
.holcim-no-bg-btn {
  --cui-btn-bg: #{$transparent};
  --cui-btn-border-color: #{$transparent};
  --cui-btn-hover-bg: #{$transparent};
  --cui-btn-hover-border-color: #{$transparent};
  --cui-btn-active-bg: #{$transparent};
  --cui-btn-active-border-color: #{$transparent};
  --cui-btn-disabled-bg: #{$transparent};
  --cui-btn-disabled-border-color: #{$transparent};
}
.holcim-no-bg-btn-hover {
  --cui-btn-hover-color: #{$holcim-dark-blue};
  --cui-btn-hover-bg: #{$transparent};
  --cui-btn-hover-border-color: #{$transparent};
}
.holcim-dark-blue-border-btn {
  color: #{$holcim-dark-blue};
  --cui-btn-hover-color: #{$holcim-dark-blue};
  --cui-btn-bg: #{$transparent};
  --cui-btn-hover-bg: #{$transparent};
  --cui-btn-active-bg: #{$transparent};
  --cui-btn-disabled-bg: #{$transparent};
}
.holcim-dark-gray-border-btn {
  color: #{$holcim-dark-gray};
  --cui-btn-hover-border-color: #{$holcim-dark-gray};
  --cui-btn-hover-color: #{$holcim-dark-gray};
  --cui-btn-bg: #{$transparent};
  --cui-btn-hover-bg: #{$transparent};
  --cui-btn-active-bg: #{$transparent};
  --cui-btn-disabled-bg: #{$transparent};
}
.holcim-light-btn:hover,
.holcim-light-btn:active,
.holcim-dark-btn:hover,
.holcim-dark-btn:active {
  //opacity: 0.6;
}
.holcim-dark-blue-btn {
  --cui-btn-bg: #{$holcim-dark-blue};
  --cui-btn-border-color: #{$holcim-dark-blue-darker};
  --cui-btn-hover-bg: #{$holcim-dark-blue-darker};
  --cui-btn-hover-border-color: #{$holcim-dark-blue-darker};
  --cui-btn-active-bg: #{$holcim-dark-blue-darker};
  --cui-btn-active-border-color: #{$holcim-dark-blue-darker};
  --cui-btn-disabled-bg: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-border-color: #{$holcim-dark-gray-darker};
}
.holcim-dark-blue-on-disable {
  --cui-btn-disabled-bg: #{$transparent};
  --cui-btn-disabled-border-color: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-color: #{$holcim-dark-gray-darker};
}
.holcim-bright-blue-btn {
  --cui-btn-bg: #{$holcim-bright-blue};
  --cui-btn-border-color: #{$holcim-bright-blue-darker};
  --cui-btn-hover-bg: #{$holcim-bright-blue-darker};
  --cui-btn-hover-border-color: #{$holcim-bright-blue-darker};
  --cui-btn-active-bg: #{$holcim-bright-blue-darker};
  --cui-btn-active-border-color: #{$holcim-bright-blue-darker};
  --cui-btn-disabled-bg: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-border-color: #{$holcim-dark-gray-darker};
}
.holcim-bright-green-btn {
  --cui-btn-bg: #{$holcim-bright-green};
  --cui-btn-border-color: #{$holcim-bright-green-darker};
  --cui-btn-hover-bg: #{$holcim-bright-green-darker};
  --cui-btn-hover-border-color: #{$holcim-bright-green-darker};
  --cui-btn-active-bg: #{$holcim-bright-green-darker};
  --cui-btn-active-border-color: #{$holcim-bright-green-darker};
  --cui-btn-disabled-bg: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-border-color: #{$holcim-dark-gray-darker};
}
.holcim-dark-turquoise-btn {
  --cui-btn-bg: #{$holcim-dark-turquoise};
  --cui-btn-border-color: #{$holcim-dark-turquoise-darker};
  --cui-btn-hover-bg: #{$holcim-dark-turquoise-darker};
  --cui-btn-hover-border-color: #{$holcim-dark-turquoise-darker};
  --cui-btn-active-bg: #{$holcim-dark-turquoise-darker};
  --cui-btn-active-border-color: #{$holcim-dark-turquoise-darker};
  --cui-btn-disabled-bg: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-border-color: #{$holcim-dark-gray-darker};
}
.holcim-bright-turquoise-btn {
  --cui-btn-bg: #{$holcim-bright-turquoise};
  --cui-btn-border-color: #{$holcim-bright-turquoise-darker};
  --cui-btn-hover-bg: #{$holcim-bright-turquoise-darker};
  --cui-btn-hover-border-color: #{$holcim-bright-turquoise-darker};
  --cui-btn-active-bg: #{$holcim-bright-turquoise-darker};
  --cui-btn-active-border-color: #{$holcim-bright-turquoise-darker};
  --cui-btn-disabled-bg: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-border-color: #{$holcim-dark-gray-darker};
}
.holcim-dark-gray-btn {
  --cui-btn-bg: #{$holcim-dark-gray};
  --cui-btn-border-color: #{$holcim-dark-gray};
  --cui-btn-hover-bg: #{$holcim-dark-gray-darker};
  --cui-btn-hover-border-color: #{$holcim-dark-gray-darker};
  --cui-btn-active-bg: #{$holcim-dark-gray-darker};
  --cui-btn-active-border-color: #{$holcim-dark-gray-darker};
  --cui-btn-disabled-bg: #{$holcim-bright-gray};
  --cui-btn-disabled-border-color: #{$holcim-bright-gray};
}
.holcim-light-gray-btn {
  --cui-btn-bg: #{$holcim-bright-gray-darker};
  --cui-btn-border-color: #{$holcim-bright-gray-darker};
  --cui-btn-hover-bg: #{$holcim-bright-gray-darker};
  --cui-btn-hover-border-color: #{$holcim-bright-gray-darker};
  --cui-btn-active-bg: #{$holcim-bright-gray-darker};
  --cui-btn-active-border-color: #{$holcim-bright-gray-darker};
  --cui-btn-disabled-bg: #{$holcim-bright-gray};
  --cui-btn-disabled-border-color: #{$holcim-bright-gray};
}
.holcim-light-gray-not-bg-btn {
  --cui-btn-color: black;
  --cui-btn-bg: #{$transparent};
  --cui-btn-border-color: #{$holcim-bright-gray-darker};
  --cui-btn-hover-color: black;
  --cui-btn-hover-bg: #{$transparent};
  --cui-btn-hover-border-color: #{$holcim-bright-gray-darker};
  --cui-btn-active-bg: #{$transparent};
  --cui-btn-active-color: black;
  --cui-btn-active-border-color: #{$holcim-bright-gray-darker};
  --cui-btn-disabled-bg: #{$holcim-bright-gray};
  --cui-btn-disabled-border-color: #{$holcim-bright-gray};
  border-radius: 35px;
  font-size: 12px;
  text-transform: uppercase;
  &.selected {
    --cui-btn-bg: #{$holcim-bright-gray};
    --cui-btn-hover-bg: #{$holcim-bright-gray};

  }
}
.btn-soft-radius {
  border-radius: 6px;
}
button {
  &.holcim-button {
    border-radius: 35px;
    padding: 12px 20px;
    font-size: 12px;
    font-weight: 700;
  }
}
.go-back {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #{$holcim-dark-blue};
  &:hover {
    --cui-btn-hover-color: #{$holcim-dark-blue};
  }
  &.disabled {
    color: #{$holcim-dark-blue};
  }
}

/* ICONS */
.holcim-icon {
  vertical-align: text-bottom;
  padding-top: 0;
  padding-bottom: 0;
}


/* ACTIONS */
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}

/* POSITION */
.left {
  float: left;
}
.right {
  float: right;
}

/* SIDEBAR */
.sidebar-brand {
  justify-content: left;
  padding: var(--cui-sidebar-nav-link-padding-y) var(--cui-sidebar-nav-link-padding-x);
}

/* MENU */
.sidebar-nav {
  .nav-title {
    font-size: 18px;
  }
  .nav-item {
    a {
      --cui-sidebar-nav-icon-width: 3rem;
      &.active {
        font-weight: 700;
      }
      img {
        margin-right: 1rem;
      }
    }
  }
  .nav-group {
    a {
      &.active {
        font-weight: 700;
      }
      img {
        margin-right: 1rem;
      }
    }
    .nav-group {
      a {
        --cui-sidebar-nav-icon-width: 3rem;
      }
      .nav-item {
        font-size: smaller;
      }
    }
  }
}

/* TOASTER */
.toast {
  &.holcim-toast {
    --cui-toast-box-shadow: unset;
    --cui-toast-border-radius: 0;
    --cui-toast-max-width: 100%;
    &.success {
      --cui-toast-border-color: #{$holcim-bright-green};
      --cui-toast-bg: rgba(148, 193, 46, 0.25);
      &.holcim-toast-full-opacity {
        --cui-toast-bg: rgba(148, 193, 46, 0.6);
        color: white;
      }
    }
    &.danger {
      --cui-toast-border-color: #{$holcim-bright-red};
      --cui-toast-bg: rgba(227, 0, 15, 0.25);
      &.holcim-toast-full-opacity {
        --cui-toast-bg: rgba(227, 0, 15, 0.6);
        color: white;
      }
    }
    &.big {
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
}

/* COMMON INPUT */
.form-switch {
  .form-check-label {
    padding-top: 0;
    padding-left: 10px;
  }
  input {
    --cui-form-check-input-checked-bg-color: #{$holcim-bright-green};
    &:checked {
      --cui-form-check-input-checked-border-color: #{$holcim-bright-green};
    }
  }
}

/* FORMS */
form {
  .form-switch {
    .form-check-input {
      width: 42px;
    }
    .form-check-label {
      padding-top: 0;
      padding-left: 10px;
    }
  }
  &.was-validated {
    .form-check-input {
      &:valid {
        border-color: rgba(0, 0, 21, 0.25);
        &:checked {
          background-color: #{$holcim-bright-green};
          border-color: #{$holcim-bright-green};
        }
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(148, 193, 46, 0.25);
        }
      }
    }
  }
  &.small-font {
    .form-control {
      font-size: 14px;
    }
    .form-select {
      font-size: 14px;
    }
  }
}
.col-form-label {
  font-size: 16px;
  font-weight: 700;
  --cui-form-label-color: #{$fullblack};
  &.filter-label {
    --cui-form-label-color: #{$holcim-dark-blue};
  }
}
.col-form-label {
  font-size: 16px;
  font-weight: 700;
  --cui-form-label-color: #{$fullblack};
  &.filter-label {
    --cui-form-label-color: #{$holcim-dark-blue};
  }
}
.col-form-value {
  font-size: 14px;
  --cui-form-label-color: #{$fullblack};
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
}
.form-control {
  --cui-input-bg: white;
  /*height: 48px;*/
  height: auto;
  font-size: small;
  /*border-radius: 0;
  border-color: #{$holcim-dark-blue};*/
  &.error {
    border: 1px solid #{$holcim-bright-red};
  }
  &::placeholder {
    font-size: smaller;
  }
}
.form-select {
  --cui-form-select-bg: white;
  --cui-form-select-border-color: #{$holcim-text-gray};

  /*padding: 0.2em 3.5em 0.2em 1em !important;
  height: 48px;*/

  height: auto;
  padding-top: calc(0.375rem + 1px) !important;
  padding-bottom: calc(0.375rem + 1px) !important;
  font-size: small;

  background-image:
    linear-gradient(45deg, transparent 50%, #{$holcim-text-gray} 50%),
    linear-gradient(135deg, #{$holcim-text-gray} 50%, transparent 50%),
    linear-gradient(to right, white, white);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0 !important;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 3em;
  background-repeat: no-repeat;
  /*--cui-form-select-border-color: #{$holcim-dark-blue};
  border-radius: 0;
  background-image:
    linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, #{$holcim-dark-blue}, #{$holcim-dark-blue});
  padding: 0.5em 3.5em 0.5em 1em !important;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;*/
  &.error {
    border: 1px solid #{$holcim-bright-red};
  }
  &:focus {
    border-color: #{$holcim-dark-blue};
    box-shadow: unset;
  }
  &[aria-label] {
    font-size: small;
  }
  &.small-form-select {
    width: auto;
    height: auto;
    display: inline-block;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
    background-position: calc(100% - 19px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
  }
}
.react-datetime-picker {
  --cui-form-select-bg: white;
  /*border: 1px solid #{$holcim-dark-blue};
  padding: 0.4rem 0.2rem;
  border-radius: 0;
  background-image:
    linear-gradient(45deg, #{$holcim-dark-blue}, #{$holcim-dark-blue}),
    linear-gradient(135deg, #{$holcim-dark-blue}, #{$holcim-dark-blue}),
    linear-gradient(to right, #{$holcim-dark-blue}, #{$holcim-dark-blue});*/
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0 !important;
 /* background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;*/
  background-repeat: no-repeat;

  border: 1px solid #{$holcim-text-gray};
  padding: 0.2rem !important;
  padding-top: calc(0.375rem + 1px) !important;
  padding-bottom: calc(0.375rem + 1px) !important;
  border-radius: 4px;
  background-image:
    linear-gradient(45deg, #{$holcim-text-gray}, #{$holcim-text-gray}),
    linear-gradient(135deg, #{$holcim-text-gray}, #{$holcim-text-gray}),
    linear-gradient(to right, #{$holcim-text-gray}, #{$holcim-text-gray});
  background-size:
    5px 5px,
    5px 5px,
    2.5em 3em;
  /*height: 48px;*/
  .react-datetime-picker__calendar-button {
    &:hover {
      .react-datetime-picker__button__icon {
        stroke: white !important;
      }
    }
    .react-datetime-picker__button__icon {
      stroke: white !important;
      &:hover {
        stroke: white !important;
      }
    }
  }
  >div {
    &[class$="__wrapper"] {
      border: none;
      width: 100%;
      div {
        &[class$="__inputGroup"] {
          //font-size: 0.9rem;
          color: var(--cui-form-select-color, rgba(44, 56, 74, 0.95));
          span {
            padding: 0;
          }
        }
      }
      button {
        padding: 0 6px;
      }
    }
  }
}
.holcim-searchable-select-control {
  /*border-color: #{$holcim-dark-blue} !important;
  border-radius: 0 !important;
  background-image:
    linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, #{$holcim-dark-blue}, #{$holcim-dark-blue});*/
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0 !important;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  padding: 0.5em 3.5em 0.5em 1em !important;
  box-shadow: none !important;

  border: 1px solid #{$holcim-text-gray} !important;
  background-image:
    linear-gradient(45deg, transparent 50%, #{$holcim-text-gray} 50%),
    linear-gradient(135deg, #{$holcim-text-gray} 50%, transparent 50%),
    linear-gradient(to right, white, white);
  border-radius: 4px;
  /*height: 48px;*/

  height: auto;
  padding-top: calc(0.375rem + 1px) !important;
  padding-bottom: calc(0.375rem + 1px) !important;
  font-size: small;

  .holcim-searchable-select-value-container {
    padding: 0 !important;
    margin: 0 !important;
    .holcim-searchable-select-placeholder {
      color: var(--cui-form-select-color, rgba(44, 56, 74, 0.95));
      font-size:0.8rem;
    }
    .holcim-searchable-select-input {
      padding: 0 !important;
      margin: 0 !important;
    }
    .holcim-searchable-select-multi-value {
      .holcim-searchable-select-multi-value-label {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      .holcim-searchable-select-option {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  }
  .holcim-searchable-select-indicator-container {
    display:none;
  }
}
.holcim-searchable-select-menu {
  border-radius: 0 !important;
  .holcim-searchable-select-menu-list {
    border-color: #{$holcim-dark-blue} !important;
  }
}
/*.holcim-searchable-select {
  .react-select__control {
    border-color: #{$holcim-dark-blue};
    border-radius: 0;
    background-image:
      linear-gradient(45deg, transparent 50%, white 50%),
      linear-gradient(135deg, white 50%, transparent 50%),
      linear-gradient(to right, #{$holcim-dark-blue}, #{$holcim-dark-blue});
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      100% 0 !important;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
    padding: 0.5em 3.5em 0.5em 1em !important;
    box-shadow: none !important;

    div[class*="ValueContainer"] {
      padding: 0;
      div[class*="-Input"] {
        padding: 0;
        margin: 0;
      }
    }
    div[class*="indicatorContainer"] {
      display: none;
    }
    div[id*="listbox"] {
      margin: 0 !important;
      border-radius: 0 !important;
      border-color: #{$holcim-dark-blue} !important;
    }
  }
}*/

/* FORM VALIDATION */

/* TABS */
.holcim-tabs-old {
  &.nav-tabs {
    --cui-nav-tabs-border-width: 1px;
    //--cui-nav-tabs-border-radius: 0;
    --cui-nav-tabs-link-active-color: white;
    .nav-link {
      //filter: opacity(0.8);
      &.dark-red {
        --cui-nav-tabs-border-color: #{$holcim-dark-red-darker};
        --cui-nav-tabs-link-hover-border-color: #{$holcim-dark-red-darker};
        --cui-nav-tabs-link-active-bg: rgba(#{$holcim-dark-red-rgb}, 0.8);
        --cui-nav-tabs-link-active-border-color: #{$holcim-dark-red-darker};
      }
      &.bright-blue {
        --cui-nav-tabs-border-color: #{$holcim-bright-blue-darker};
        --cui-nav-tabs-link-hover-border-color: #{$holcim-bright-blue-darker};
        --cui-nav-tabs-link-active-bg: rgba(#{$holcim-bright-blue-rgb}, 0.8);
        --cui-nav-tabs-link-active-border-color: #{$holcim-bright-blue-darker};
      }
      &.bright-gray {
        --cui-nav-tabs-border-color: #{$holcim-bright-gray-darker};
        --cui-nav-tabs-link-hover-border-color: #{$holcim-bright-gray-darker};
        --cui-nav-tabs-link-active-bg: rgba(#{$holcim-bright-gray-rgb}, 0.8);
        --cui-nav-tabs-link-active-border-color: #{$holcim-bright-gray-darker};
      }
    }
  }
}
.holcim-tabs {
  border-bottom: 2px solid var(--cui-nav-underline-border-color,#c4c9d0);
  .nav-item {
    margin-bottom: -2px;
    .nav-link {
      font-size: 14px;
      border-bottom: 2px solid transparent;
      color: var(--cui-nav-underline-link-color,#768192);
      &.active {
        background: transparent;
        color: #{$holcim-dark-blue};
        border-color: #{$holcim-dark-blue};
      }
    }
  }
}

/* TAB PANELS */
.holcim-tab-panels {
  padding: 1rem;
  font-size: 14px;
}

/* COMMON */
a {
  text-decoration: none;
}
.visibility-hidden {
  visibility: hidden;
  &.hide-on-xs {
    display: none;
  }
}
.no-pointer {
  cursor: not-allowed;
}
.pointer {
  cursor: pointer;
}
.hssp-divider {
  background-image: linear-gradient(to right,#{$holcim-bright-green},#{$holcim-bright-blue},#{$holcim-dark-blue});
  height: 6px;
  border-top: none;
  margin-bottom: 0;
}
.form-check-input{
  margin-top: 0;
  vertical-align: middle;
}
.holcim-separator {
  border-top: 1px solid #D8D8D8;
}
.holcim-separator-turquoise-dark {
  border-top: 1px solid #{$holcim-dark-turquoise};
}
.inline-list {
  border: 1px solid rgb(177, 183, 193);
  padding: 10px;
  border-radius: 6px;
  background-color: white;
  li {
    &:not(:last-child) {
      display:inline-block;
      background-color: rgba(0,0,0,0.1);
      border-radius: 15px;
      padding: 2px 5px;
      margin-right: 3px;
      margin-bottom: 3px;
      font-size: small;
    }
    &:last-child {
      display:block;
      .form-control {
        border: 0;
        &:focus {
          border-radius: 6px;
          box-shadow: unset;
          background-color: white;
        }
      }
    }
    svg {
      padding-left: 2px;
    }
  }
}
.underlined-hover {
  &:hover {
    text-decoration: underline;
  }
}

/* OUTAGE UPDATE BLOCKS */
.outage-update-block {
  border-style: solid;
  border-width: 0 0 0 3px;
  padding-left: 10px;
  border-color: #{$holcim-dark-turquoise};
  .separator {
    padding-top: 5px;
    padding-bottom: 5px;
    >div{
      border-bottom: 1px solid #{$holcim-dark-turquoise};
    }
  }
}

/* CARDS */
.card {
  border-radius: 0;
  border: 1px solid #{$holcim-dark-blue};
}

/* TITLES */
.h1-hssp {
  color: #{$holcim-dark-blue};
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  //text-transform: uppercase;
}
.h3-hssp {
  color: #{$holcim-dark-turquoise};
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

/* PAGINATION */
.pagination {
  --cui-pagination-border-color: #{$holcim-dark-blue};
  --cui-pagination-disabled-border-color: #{$holcim-dark-blue};
  --cui-pagination-border-radius: 0;
  --cui-pagination-color: #736363;
  --cui-pagination-hover-color: white;
  --cui-pagination-hover-bg: #{$holcim-dark-blue};
  --cui-pagination-hover-border-color: #{$holcim-dark-blue};
  --cui-pagination-focus-color: white;
  --cui-pagination-focus-bg: #{$holcim-dark-blue};
  --cui-pagination-focus-box-shadow: unset;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
}

/* HEADER */
.hssp-header {
  .container-fluid {
    justify-content: left;
  }
  padding-bottom: 0;
  .header-brand {
    margin-left: auto;
    &.ml-0 {
      margin-left: 0;
    }
    img {
      vertical-align: bottom;
    }
  }
  .hssp-subtitle-header {
    color: #{$holcim-dark-blue};
    padding-left: 0.5rem;
    //text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    text-align: right;
    margin-bottom: 0;
  }
  ul {
    &.hssp-logged-user-nav {
      margin-left: 0 !important;
      display: block;
      text-align: right;
    }
    .hssp-link-logged-user {
      padding-right: 0;
    }
    .hssp-logged-user {
      color: #{$holcim-dark-blue};
      /*padding-left: 0.5rem;*/
      font-weight: 500;
      font-size: 14px;
      text-align: right;
    }
    .hssp-dropdown-logged-user {
      /*width: 100%;*/
      --cui-dropdown-border-radius: 0;
      margin-top: 0.25rem;
      .logout {
        background-color: #{$holcim-light-gray};
        border-top: 1px solid #{$holcim-medium-gray};
      }
    }
  }
}
.hssp-banner {
  .alert {
    border-radius: 0;
    border: 0;
  }

}

/* HSSP PAGE CONTENT */
.inputFileHolcim {
  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    &::file-selector-button:active {
      background-color: #e5e7eb;
    }
    &::file-selector-button:hover {
      background-color: #f3f4f6;
    }
    + label {
      cursor: pointer;
    }
  }
  label {
    border-radius: 35px;
    padding: 12px 20px;
    font-size: 12px;
    font-weight: 700;
  }
}
form {
  &.hssp-content {
    &.row {
      margin-top: var(--cui-gutter-y);
    }
    .form-text {
      color: #{$fullblack};
    }
  }
}
.service-name {
  display: flex;
  flex-wrap: nowrap;
  padding-top: 10px;
  padding-bottom: 10px;
  .service-circle {
    /*background-color: rgb(255, 210, 0);*/
    width: 15px;
    height: 15px;
    margin-top: 5px;
    border-radius: 10px;
  }
  .service-name-inside {
    padding-left: 10px;
  }
}
.service-name-1 {
  font-weight: bold;
  color: #{$holcim-text-color};
}
.service-name-2 {
  font-size: 0.8rem;
}
.status-name {
  font-weight: 700;
  svg {
    margin-right: 5px;
  }
}
.maintenance {
  color: #{$holcim-bright-blue};
}
.service-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0 0.375rem 0;
  &:hover, &:active, &:focus, &:focus-visible, &:focus-within {
    background-color: unset;
    border-color: unset;
  }
  &:after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27var%28--cui-body-color%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
  }
  &:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%231a3c65%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    transform: rotate(-180deg);
  }
}
.service-button-alternative{
  display: none;
}
.service-spinner {
  margin-left: 30px;
}
.hssp-card {
  border: none;
  border-radius: 0;
  .card-header {
    border-radius: 0;
    border: unset;
    color: black;
    font-weight: bold;
    background-color: #{$white};
    font-size: 20px;
    //text-transform: uppercase;
    padding: 1rem;
    >.row {
      //min-height: 60px;
      align-items: center;
    }
  }
  .card-body {
    --cui-card-spacer-y: 0.5rem;
    background-color: white;
    //margin-top: 12px;
    //border-left: 6px solid #{$holcim-bright-green};
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    input {
      --cui-form-check-input-checked-bg-color: #{$holcim-bright-green};
      &:checked {
        --cui-form-check-input-checked-border-color: #{$holcim-bright-green};
      }
    }
    .service-countries {
      p {
        column-count: 1;
      }
    }
  }
  .osu-block {
    padding: var(--cui-card-spacer-y) var(--cui-card-spacer-x);
    border: 1px solid #{$holcim-dark-blue};
    //margin-bottom: 3px;
    +.osu-block {
      margin-top: 3px;
    }
  }
  .osu-type-label {
    font-size: 0.9rem;
    color: #{$fullblack};
  }
  .osu-type-value {
    font-weight: bold;
  }
  .osu-date-label {
    font-size: 0.9rem;
    color: #{$fullblack};
  }
  .osu-date-value {
    font-size: 0.9rem;
    color: #{$fullblack};
  }
  .osu-description {
    font-size: 0.9rem;
    text-align: justify;
    color: #{$fullblack};
  }
}

/* TABLES */
.hssp-table {
  >.col {
    padding:0;
  }
}
.hssp-table-row {
  padding-right: calc(var(--cui-gutter-x) * 0.5);
  padding-left: calc(var(--cui-gutter-x) * 0.5);
  margin-bottom: 8px;
  /*margin-top: 3px;*/
  &.row-header {
    margin-bottom: 5px;
  }
  &.row-utc-info {
    box-shadow: unset;
    text-align: right;
    color: #{$holcim-dark-blue};
    font-size: 14px;
    font-weight: 500;
    line-height: 28.8px;
    padding-right: 0;
  }
  .align-items-stretch {
    padding: 10px 0 10px 0;
    .service-name {
      padding: 0;
    }
  }
  >.row {
    min-height: 42px;
    align-items: center;
    color: #{$holcim-text-color};
    background-color: #fff;
    /*border: 1px solid #{$holcim-bright-blue};*/
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    &.header {
      min-height: 50px;
      color: #{$holcim-text-gray};
      font-weight: bold;
      /*background-color: #{$holcim-bright-blue};*/
      box-shadow: unset;
      font-size: 14px;
      svg {
        polygon {
          stroke: white;
        }
        &.selectedOrder {
          polygon {
            stroke-width: 40px;
          }
        }
      }
      div {
        &[class^="col-"] {
          padding: 0;
        }
      }
    }
    span {
      font-size: 14px;
    }
    .status-name {
      font-size: 14px;
      padding-top: 3px;
    }
    .status-date {
      font-size: 13px;
      padding-top: 3px;
      &.text-small {
        font-size: small;
      }
      .bold {
        font-size: 13px;
      }
    }
    .actions {
      button {
        padding-left: 2px;
        padding-right: 2px;
      }
    }
    input[type=checkbox] {
      border-radius: 0.1em;
      font-size: 20px;
      &:checked {
        --cui-form-check-input-checked-bg-color: black;
        --cui-form-check-input-checked-border-color: black;
      }
    }
  }
  &.hssp-indented-level-1 {
    padding: 0;
    display: none;
    &.desplegado {
      display: block;
    }
    >.col-12 {
      padding-left: 2rem;
    }
    .hssp-card {
      .card-body {
        margin-top: 0;
        padding: 0;
        border: 0;
        box-shadow: unset;
      }
    }
  }
}
.vertical-timeline {
  position: relative;
  padding-left: 45px;
  list-style: none;
  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
  }
  >li {
    position: relative;
    counter-increment: list;
    &:before {
      display: inline-block;
      content: '';
      position: absolute;
      left: -30px;
      height: 100%;
      width: 10px;
      top: 10px;
    }
    &:not(:last-child) {
      padding-bottom: 20px;
      &.default {
        &:before {
          border-left: 2px solid #{$holcim-dark-blue};
        }
      }
      &.offline {
        &:before {
          border-left: 2px solid #{$holcim-bright-red};
        }
      }
      &.performance {
        &:before {
          border-left: 2px solid #{$holcim-yellow};
        }
      }
      &.maintenance {
        &:before {
          border-left: 2px solid #{$holcim-bright-blue};
        }
      }
      &.online {
        &:before {
          border-left: 2px solid #{$holcim-bright-green};
        }
      }
    }
    &.default {
      &:after {
        border: 6px solid #{$holcim-dark-blue};
      }
    }
    &.offline {
      &:after {
        border: 6px solid #{$holcim-bright-red};
      }
    }
    &.performance {
      &:after {
        border: 6px solid #{$holcim-yellow};
      }
    }
    &.maintenance {
      &:after {
        border: 6px solid #{$holcim-bright-blue};
      }
    }
    &.online {
      &:after {
        border: 6px solid #{$holcim-bright-green};
      }
    }
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 6px;
      left: -35px;
      width: 12px;
      height: 12px;
      border: 6px solid #CCC;
      border-radius: 50%;
      background-color: #FFF;
    }
  }
}

/* SPECIFIC COLORS */
.circleDisabled {

}
.textDisabled {
  span {
    color: #{$holcim-text-gray};
  }
}

/* CHECKBOXES GRID STYLE */
.gridCheckboxes {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  &.gridCheckboxes-compact {
    .flexContainer {
      .checkbox {
        label {
          padding: 0;
        }
      }
    }
  }
  .flexContainer {
    //display: flex;
    .checkbox {
      padding-left: 0 !important;
      flex: 1;
      label {
        background-color: #{$holcim-light-gray};
        display: inline-block;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
        transition: all 250ms ease-out;
        cursor: pointer;
        padding: 10px;
        font-size: 14px;
      }
      input[type=checkbox] {
        display: none;
      }
      input[type=checkbox]:checked {
        + label {
            background-color: #{$holcim-green};
        }
      }
    }
  }
}
.gridCheckboxes-light {
  .flexContainer {
    .checkbox {
      label {
        background-color: #{$transparent};
      }
      input[type=checkbox]:checked {
        + label {
          background-color: #{$holcim-light-gray};
        }
      }
    }
  }
  &.highlight-checked {
    .checkbox {
      input[type=checkbox]:checked {
        + label {
          background-color: #{$holcim-medium-gray};
        }
      }
    }
  }
}
/* FOOTER */
.footer {
  --cui-footer-border: none;
  --cui-footer-min-height: 98px;
  .social_wrapper {
    display: flex;
    flex-direction: row;

    .icon-rrss {
      background: #ffffff 0 0 no-repeat padding-box;
      width: 39px;
      height: 37px;
      border-radius: 25px;
      position: relative;
      margin-right: 0.625rem;

      svg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
}

/* PUBLIC PAGE SPECIAL STYLE */
.public-view {
  /*background-color: white;*/
  .hssp-table-row {
    >.row {
      /*border: 1px solid #{$holcim-dark-blue};*/
      &.header {
        //background-color: #{$holcim-dark-blue};
      }
    }
  }
  .hssp-card {
    .card-header {
      background-color: #{$holcim-dark-blue};
    }
    .card-body {
      border: 1px solid #{$holcim-dark-blue};
    }
  }
  .outage-update-block {
    border-color: #{$holcim-dark-blue};
    .separator {
      >div{
        border-bottom: 1px solid #{$holcim-dark-blue};
      }
    }
  }
}

/* SPINNER */
.body-relative {
  position: relative;
}
.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.7);
  z-index: 10000;
  &.light {
    background-color: rgba(0,0,0,0.2);
  }
  &.dark {
    background-color: rgba(0,0,0,0.7);
    color: white;
    font-weight: normal;
  }
  .spinner-content {
    text-align: center;
    &.white {
      font-size: 1.2rem;
      color: #FFFFFF;
    }
    &.spinner-with-text {
      background-color: #F5F5F5;
      padding: 1rem;
      .spinner-border {
        border: 0.25em solid var(--cui-body-color);
        border-right-color: transparent;
      }
    }
    position: absolute;
    .spinner-border {
      border: 0.25em solid white;
      border-right-color: transparent;
    }
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* UPLOADING OVERLAY */
.overlay {
  z-index: 9;
  background-color: rgba(0,0,0,0.2);
  opacity: 0.7;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*position: absolute;*/
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* TOOLTIP */
.holcim-tooltip {
  position: relative;
  display: inline-block;
  &:hover {
    .holcim-white-tooltip {
      visibility: visible;
    }
  }
  .holcim-white-tooltip {
    visibility: hidden;
    width: 300px;
    bottom: 100%;
    left: 0;
    margin-left: -150px;
    background-color: white;
    color: black;
    text-align: center;
    padding: 5px;
    position: absolute;
    z-index: 1;
    font-weight: normal;
    font-size: small;
    border: 1px solid #{$holcim-dark-blue};
  }
}

/* WYSIWYG EDITOR */
.holcim-toolbar-editor {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  user-select: none;
}
.holcim-wrapper-editor {
  margin-top: 5px !important;
  width: 100% !important;
  display: block !important;
}
.holcim-editor {
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  min-height: 200px;
}
.holcim-outage-description {
  /*box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);*/
  border: 1px solid #f1f1f1;
}
.holcim-collapse-span {
  font-size: 14px;
  font-weight: 700;
  color: #{$holcim-dark-blue};
}

/* CKEDITOR */
.ck.ck-heading_heading1 {
  font-size:20px;
}
.ck.ck-heading_heading2 {
  font-size:17px;
}
.ck.ck-heading_heading3 {
  font-size:14px;
}
.ck[class*=ck-heading_heading] {
  font-weight:700;
}
.ck-rounded-corners {
  .ck {
    &.ck-editor__top {
      .ck-sticky-panel {
        .ck-toolbar {
          border-top-left-radius: 0.375rem !important;
          border-top-right-radius: 0.375rem !important;
        }
      }
    }
    &.ck-editor__main {
      >.ck-editor__editable {
        &.ck-rounded-corners {
          border-bottom-left-radius: 0.375rem !important;
          border-bottom-right-radius: 0.375rem !important;
        }
      }
    }
  }
}

.marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
.marker-green {
  background-color: var(--ck-highlight-marker-green);
}
.marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
.marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
.pen-red {
  background-color: transparent;
  color: var(--ck-highlight-pen-red);
}
.pen-green {
  background-color: transparent;
  color: var(--ck-highlight-pen-green);
}
.text-huge {
  font-size: 1.8em;
}
.text-big {
  font-size: 1.4em;
}
.text-small {
  font-size: .85em;
}
.text-tiny {
  font-size: .7em;
}
blockquote {
  border-left: 5px solid #ccc;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.ck.ck-button.ck-heading_heading1, .ck-content h1.hssp {
  color: #{$holcim-dark-blue};
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.ck.ck-button.ck-heading_heading2, .ck-content h2.hssp {
  color: #{$holcim-dark-blue};
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.ck.ck-button.ck-heading_heading3, .ck-content h3.hssp {
  color: #{$holcim-dark-blue};
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

/* NOTIFICATION LIST */
.holcim-tab-button {
  letter-spacing: 0.5px;
  color: #{$holcim-dark-blue};
  padding: 4px !important;
  font-size: small;
  &:hover {
    color: #{$holcim-dark-blue};
    /*background-color: rgba(29,67,112,0.1);
    border: 1px solid rgba(29,67,112,0.1);*/
    background-color: rgba(64,64,64,0.1);
    border: 1px solid rgba(64,64,64,0.1);
    border-radius: 0;
  }
}
.holcim-tab-button-selected {
  border: 1px solid #{$holcim-dark-blue};
  background-color: #{$holcim-dark-blue};
  border-radius: 0;
  letter-spacing: 0.5px;
  color: #ffffff !important;
  padding: 4px !important;
  font-size: small;
  &:hover {
    background-color: rgba(29,67,112,0.8);
  }
}
/* NOTIFICATION VIEW */
.containerScrollBox {
  border: 1px solid rgb(177, 183, 193);
  padding: 2px;
  border-radius: 6px;
  background-color: white;
  &.loading {
    background-color: rgba(0,0,0,0.1);
  }
  .scrollBox {
    height:200px;
    overflow: auto;
    .flexContainer {
      border-bottom: 1px solid #{$holcim-light-gray};
      /*&.odd {
        background-color: #f6f6f6;
      }*/
      .leftside {
        float: left;
        /*max-width: 85%;*/
      }
      .rightside {
        /*float: right;*/
        float: left;
        max-width: 85%;
        padding-left: 10px;
      }
      .form-check-label {
        font-size: small;
      }
    }
  }
}
.notification-view {
  .container-lg {
    max-width: 100%;
    .form-label {
      .language-label {
        background-color: #{$holcim-light-gray};
        margin-bottom: 0;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: 600;
      }
    }
    .form-select {
      height: auto;
      padding-top: calc(0.375rem + 1px) !important;
      padding-bottom: calc(0.375rem + 1px) !important;
      font-size: small;
    }
    .form-control {
      height: auto;
      font-size: small;
      &.react-datetime-picker {
        padding-top: 0.2rem !important;
        padding-bottom: 0.2rem !important;
      }
    }

    .form-check-label {
      font-size: 14px;
    }

    header {
      max-width: 600px;
    }
    h2 {
      font-size: 12pt;
      padding: 5px;
      background: #ccc;
    }
    p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-size: 13px;
    }
    .accordion {
      max-width: 600px;
      width: 100%;
      padding: 0;
      list-style: none;
    }
    .accordion li {
      padding-top: 10px;
      font-size: 13px;
    }
    .accordion-item {
      border: none;
    }
    .accordion-item-content {
      display: none !important;
      padding: 5px;
      background: #f9f9f9;
      border: 1px solid #ddd;
    }
    .accordion .accordion-item-content:hover {
      border: 1px solid #0000ff;
    }
    .accordion .accordion-item:hover .accordion-item-content,
    .accordion .accordion-item-default .accordion-item-content,
    .accordion .accordion-item--default .accordion-item-content {
      display: inherit !important;
    }
    .header-img {
      width: 100%;
    }
    .blue-bold-text {
      color: blue;
      font-weight: bold;
      font-style: italic;
    }
  }
}

/* GALLERY MODAL */
.gallery-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  .gallery-item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    aspect-ratio: 3.5;

    &.selected {
      border: 2px solid #{$holcim-dark-blue};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
  .gallery-item-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    margin: 0;
  }
}
/* RESPONSIVE */
@media(min-width: $breakpoint-sm) {
  .text-right-on-xs {
    text-align: left;
  }
  .pt-2-on-xs {
    padding-top: 0 !important;
  }
  .visibility-hidden {
    &.hide-on-xs {
      display: block;
    }
  }
  .service-button-alternative{
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0.375rem 0 0.375rem 0;
    &:after {
      flex-shrink: 0;
      width: 1.25rem;
      height: 1.25rem;
      margin-left: auto;
      content: "";
      background-size: 1.25rem;
    }
  }
  .hssp-card {
    .card-body {
      .service-countries {
        p {
          column-count: 2;
        }
      }
    }
  }

  .flexFullWidthCol {
    display: flex;
    align-items: center;
    .left-item {
      white-space: nowrap;
      padding-right: 1em;
    }
    .right-item {
      flex-grow: 1;
    }
  }
}
@media(min-width: $breakpoint-md) {
  .px-5-md {
    padding: 3rem !important;
  }
  .hssp-card {
    .card-body {
      .service-countries {
        p {
          column-count: 2;
        }
      }
    }
  }
}
@media(min-width: $breakpoint-lg) {
  .pt-2-on-md {
    padding-top: 0 !important;
  }
  .hssp-card {
    .card-body {
      .service-countries {
        p {
          column-count: 3;
        }
      }
    }
  }
  .hssp-table-row {
    >.row {
      &.header {
        font-size: 16px;
        div {
          &[class^="col-"] {
            padding-right: calc(var(--cui-gutter-x) * 0.5);
            padding-left: calc(var(--cui-gutter-x) * 0.5);
          }
        }
      }
    }
  }
}
@media(min-width: $breakpoint-xl) {
  .hssp-card {
    .card-body {
      .service-countries {
        p {
          column-count: 4;
        }
      }
    }
  }
  .hssp-table-row {
    > .row {
      .actions {
        button {
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }
  }
}
@media(min-width: $breakpoint-xxl) {
}

